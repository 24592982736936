import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt from 'jsonwebtoken'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Anasayfa',
            },
        },
        {
            path: '/product',
            name: 'product',
            component: () => import('@/views/product/ProductList.vue'),
            meta: {
                pageTitle: 'Ürün Listele',
            },
        },
        {
            path: '/customer',
            name: 'customer',
            component: () => import('@/views/customer/CustomerList.vue'),
            meta: {
                pageTitle: 'Müşteri Listele',
            },
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('@/views/user/UserList.vue'),
            meta: {
                pageTitle: 'Kullanıcı Listele',
            },
        },
        {
            path: '/orderAdd',
            name: 'orderAdd',
            component: () => import('@/views/order/OrderAdd.vue'),
            meta: {
                pageTitle: 'Sipariş Ekle',
            },
        },
        {
            path: '/orderList',
            name: 'orderList',
            component: () => import('@/views/order/OrderList.vue'),
            meta: {
                pageTitle: 'Sipariş Listele',
            },
        },
        {
            path: '/orderEdit',
            name: 'orderEdit',
            component: () => import('@/views/order/OrderEdit.vue'),
            meta: {
                pageTitle: 'Sipariş Düzenle',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

/*
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('userToken');

    if (to.name === 'login') {
        if (token) {
            next({name: 'home'});
        } else {
            next();
        }
    } else {
        if (!token) {
            next({name: 'login'});
        } else {
            let val = AES_CBC.decrypt(token);
            if (val !== '') {
                next();
            } else {
                localStorage.removeItem('userToken');
                next({name: 'login'});
            }
        }
    }
})
*/

router.beforeEach((to, from, next) => {
    const store = router.app.$options.store
    const token = store.getters['token/getToken']

    if(to.name === 'user' && !store.getters["token/getIsAdmin"]) {
        next({name:'home'})
    }

    if (to.name === 'login') {
        if (token) {
            const val = jwt.decode(token)
            if (val.exp < Date.now() / 1000) {
                localStorage.removeItem('userToken')
                return next()
            }

            next({name: 'home'})
        } else {
            next();
        }
    } else {
        if (!token) {
            next({name: 'login'});
        } else {
            const val = jwt.decode(token)
            if (val.exp < Date.now() / 1000) {
                localStorage.removeItem('userToken')
                next({name: 'login'});
                return
            }
            next()
        }
    }
/*
    if (!token && to.name !== 'login') {
        next({name: 'login'})
    } else {
        try {
            if (token === "" && to.name !== 'login') {
                next()
            }
            const val = jwt.decode(token)
            if (val.exp < Date.now() / 1000) {
                localStorage.removeItem('userToken')
                next({name: 'login'})
                return
            }
            next()
        } catch (err) {
            localStorage.removeItem('userToken')
            next({name: 'login'})
        }
    }*/
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
