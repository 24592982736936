import AES_CBC from '@/libs/aes-cbc'
import jwt from 'jsonwebtoken'

export default {
    namespaced: true,
    state: {
        jwtToken: AES_CBC.decrypt(localStorage.getItem('userToken')),
        orderSelect: null,
    },
    getters: {
        getToken(state) {
            return state.jwtToken
        },
        getUserName(state) {
            return jwt.decode(state.jwtToken).username
        },
        getIsAdmin(state) {
            return jwt.decode(state.jwtToken).isAdmin
        },
        deleteLocalStorageToken: (state) => () => {
            state.jwtToken = null
            localStorage.removeItem('userToken')
        },
        getOrderSelect(state) {
            return state.orderSelect;
        },
    },
    mutations: {
        changeJwtToken(state, val) {
            state.jwtToken = val
        },
        changeOrder(state, val) {
            state.orderSelect = val
        },
    },
    actions: {
        changeToken({commit}, val) {
            commit('changeJwtToken', val)
        },
        changeOrderSelect({commit}, val) {
            commit('changeOrder', val)
        },
    },
}
