const CryptoJS = require("crypto-js");

const _secretKey = "1234asd";

export default {
    encrypt: function(message) {
        return CryptoJS.AES.encrypt(message, _secretKey).toString();
    },

    decrypt: function(ciphertext) {
        try {
            if(ciphertext) {
                const bytes = CryptoJS.AES.decrypt(ciphertext, _secretKey);
                return bytes.toString(CryptoJS.enc.Utf8);
            } else {
                return ""
            }
        } catch (err) {
            return ''
        }
    },
};
