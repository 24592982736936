import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.prototype.$toastApiResponse = function (res) {
  if (res.status) {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: res.message,
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  } else {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: res.message,
        icon: 'AlertCircleIcon',
        variant: 'danger',
      },
    })
  }
}

Vue.prototype.$toastBaha = function (message, type = 'success') {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: message,
      icon: 'EditIcon',
      variant: type,
    },
  })
}

Vue.prototype.$swalDeleteAsk = function () {
  return new Promise((resolve) => {
    this.$swal({
      title: 'Silmek istediğinize emin misiniz?',
      text: 'Yapılan işlem geri alınamaz!',
      icon: 'warning',
      showClass: {
        popup: 'animate__animated animate__shakeX',
      },
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })
        .then(result => {
          resolve(result.value)
        })
  })
}

// Composition API
Vue.use(VueCompositionAPI)

Vue.prototype.JWT_TOKEN_SECRET = 'c48ed3b3f7d8cecfc19c1911e60a0d199d91236f'

axios.defaults.baseURL = 'https://apistoktakip.bahadirduzcan.com.tr/'
//axios.defaults.baseURL = 'http://localhost:5656/'
axios.defaults.headers.common.Authorization = `${store.getters['token/getToken'] ?? ''}`

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
